import Footer from './Footer'
import Background from '../../Assets/hero-bg.png'

const Layout = ({children}) => {
  const backgroundStyle = {
    backgroundImage: `url("${Background}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%'
    /* Add other background properties as needed */
  };
  return(
    <div style={backgroundStyle}>
      <div style={{paddingTop: 50, minHeight: '80vh'}}>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
