import React from 'react';
import { Container, Grid, Menu, Segment, Header, Image, Card, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import Logo from '../../Assets/logo.png'

const Footer = () => {
  return (
    <div style={{paddingTop: 100, paddingBottom: 10, margin: 'auto', textAlign: 'center'}}>
        <Image src={Logo} size="tiny" style={{margin: 'auto'}} as={Link} to="/"/>
        <br/>
        <div>
        Email: <a href="mailto:info@corpolatech.com"><u>info@corpolatech.com</u></a>
        </div>
        <div style={{fontSize:12, fontWeight:'bold'}}>
          Copyright © {new Date().getFullYear()} Corpola Tech Pvt. Ltd. - All rights reserved.
        </div>
    </div>
  );
};

export default Footer
